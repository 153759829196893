import React from "react";
import {
  avatarImg,
  teamProfileImg1,
  teamProfileImg12,
  teamProfileImg14,
  teamProfileImg15,
  teamProfileImg16,
  teamProfileImg17,
  teamProfileImg18,
  teamProfileImg19,
  teamProfileImg2,
  teamProfileImg3,
  teamProfileImg4,
  teamProfileImg6,
  teamProfileImg7,
  teamProfileImg9,
} from "..";
import { Link } from "react-router-dom";

function OurTeam() {
  // const [showResults, setShowResults] = useState(false);
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          VNT Core <span className="textGrade titleArrow">Management</span>
        </h2>
        <p>
          Version Next consists of team that loves accepting challenges and
          contributes to give a positive result towards every project
          undertaken. Our workaholic and experienced team work hard to create a
          different product that takes your product to a new level.
        </p>
      </div>
      <div className="teamContainer">
        <div className="row ">
          <div className="col-md-6 col-lg-4 col-12 col-xl-3 xtraBtm">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgYellowBlob">
                <img
                  src={teamProfileImg2}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgYellow">
                  <p>Mr. Sanyogg Shelar</p>
                </div>
                <span>CEO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                “Catalyst of progress, gateway to innovation, and the bridge
                between dreams and reality.”
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link
                    to="https://www.linkedin.com/in/sanyogs"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link to="https://sanyog.in" target="_blank">
                    <li>
                      <i className="fas fa-globe-asia"></i>
                    </li>
                  </Link>
                  <Link
                    to="https://profiles.wordpress.org/codexdemon/"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-wordpress"></i>
                    </li>
                  </Link>

                  <Link to="https://twitter.com/sanyog" target="_blank">
                    <li>
                      {/* <i className="fab fa-twitter-square"></i> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgRedBlob">
                <img
                  src={teamProfileImg3}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgRed">
                  <p>Mr. Sumeet Rathod</p>
                </div>
                <span>CTO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "We are what we repeatedly do; excellence, then, is not an act
                but a habit."
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link to="https://www.linkedin.com/in/sumeetrathod">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link to="https://twitter.com/srtrend">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgBlueBlob">
                <img
                  src={teamProfileImg1}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgGreen ">
                  <p>Mr. Arvind Baraskar</p>
                </div>
                <span>CMO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "It is our choices, that show what we truly are, far more than
                our abilities and creativity."
              </p>

              <div className="profileSocialLinks">
                <ul>
                  <Link to="https://www.linkedin.com/in/arvindbaraskar/">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link
                    to="https://profiles.wordpress.org/arvindbaraskar/"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-wordpress"></i>
                    </li>
                  </Link>

                  <Link to="https://twitter.com/ArvindBaraskar">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgGreenBlob">
                {
                  //   <svg
                  //   xmlns="http://www.w3.org/2000/svg"
                  //   version="1.1"
                  //   viewBox="0 0 500 500"
                  //   width="100%"
                  //   id="blobSvg"
                  //   style={{ opacity: 1 }}
                  //   filter="blur(0px)"
                  //   transform="rotate(102)"
                  //   className="yellowBlobSvg"
                  // >
                  //   <path id="blob" fill="#18d5e9" style={{ opacity: 1 }}>
                  //     <animate
                  //       attributeName="d"
                  //       dur="13000ms"
                  //       repeatCount="indefinite"
                  //       values="M405.0078,325.44624Q400.89248,400.89248,325.44624,434.97549Q250,469.0585,165.42535,444.1039Q80.8507,419.1493,84.75627,334.57465Q88.66184,250,94.44262,175.1117Q100.2234,100.2234,175.1117,82.29749Q250,64.37159,306.73538,100.45042Q363.47075,136.52925,386.29693,193.26462Q409.12312,250,405.0078,325.44624Z;M421.63508,307.39005Q364.7801,364.7801,307.39005,427.43403Q250,490.08796,191.6822,428.36178Q133.3644,366.6356,70.9089,308.3178Q8.4534,250,54.21728,174.99058Q99.98115,99.98115,174.99058,81.49686Q250,63.01257,330.66021,75.84607Q411.32042,88.67958,444.90524,169.33979Q478.49006,250,421.63508,307.39005Z;M408.24461,332.63257Q415.26513,415.26513,332.63257,434.71568Q250,454.16622,179.33614,422.74697Q108.67228,391.32772,65.87585,320.66386Q23.07942,250,63.27221,176.73251Q103.46501,103.46501,176.73251,63.02288Q250,22.58075,311.86507,74.4253Q373.73015,126.26985,387.47712,188.13493Q401.22409,250,408.24461,332.63257Z;M395.5,320Q390,390,320,400Q250,410,172,408Q94,406,59,328Q24,250,70.5,183.5Q117,117,183.5,108Q250,99,335,89.5Q420,80,410.5,165Q401,250,395.5,320Z;M405.0078,325.44624Q400.89248,400.89248,325.44624,434.97549Q250,469.0585,165.42535,444.1039Q80.8507,419.1493,84.75627,334.57465Q88.66184,250,94.44262,175.1117Q100.2234,100.2234,175.1117,82.29749Q250,64.37159,306.73538,100.45042Q363.47075,136.52925,386.29693,193.26462Q409.12312,250,405.0078,325.44624Z"
                  //     />
                  //   </path>
                  //   </svg>
                }
                <img
                  src={teamProfileImg4}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Vinayak Parab</p>
                </div>
                <span>CFO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "We are what we repeatedly do; excellence, then, is not an act
                but a habit."
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link to="http://linkedin.com/in/vinayak-parab-2a7a8615">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtPlus">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg9}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Miss. Hartali Patil</p>
                </div>
                <span>Human Resource</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg19}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Akash Jain </p>
                </div>
                <span>Marketing Strategist</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg17}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Shailesh Kadam </p>
                </div>
                <span>Art Director</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg14}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mrs. Prajakta Rajeshirke</p>
                </div>
                <span>Sr. Software Tester</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg15}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Pranav Zende</p>
                </div>
                <span>Sr. Frontend Developer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg6}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Suresh A.</p>
                </div>
                <span>UI/UX Designer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg7}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Miss. Shrutika Baraskar</p>
                </div>
                <span>Visualizer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg12}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Amit Prajapati</p>
                </div>
                <span>Jr. PHP Developer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img src={avatarImg} alt="Profile" className="proTeamImg " />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Vishal Rana</p>
                </div>
                <span>Sr. PHP Developer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg18}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Ketan Vyas</p>
                </div>
                <span>Sr. PHP Developer</span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3 mtMinus ">
            <div className="teamCardWrap">
              <div className="teamProfileImg">
                <img
                  src={teamProfileImg16}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Mangesh Shelar</p>
                </div>
                <span>Admin</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
