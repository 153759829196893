import React, { useRef } from "react";
import { whitelogo } from "../index";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

function Footer() {
  const [isIndus, setPage] = useState(false);
  const locationN = useLocation();
  const scrollRef = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };
  useEffect(() => {
    if (locationN.pathname === "/") {
      setPage(false);
    } else {
      setPage(true);
    }
  }, [locationN]);
  return (
    <section id="footer">
      <div className="container-fluid px-0">
        <footer id="pq-footer" className="style-1">
          <div className="pq-footer-style-1">
            <div className="pq-footer-top">
              {isIndus ? (
                <div className="fooExtraDiv"></div>
              ) : (
                <div className="pq-footer-bottom-list">
                  <div className="container">
                    <div className="row g-0">
                      <div className="col-lg-4 p-0">
                        <div className="pq-footer-items">
                          <i className="fas fa-map-marker-alt"></i>
                          <div className="pq-footer-items-info">
                            <h4>Address</h4>
                            <span>
                              607, Pranik Chamber, Saki Vihar Road, Sakinaka
                              Metro, Mumbai.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="pq-footer-items">
                          <i className="fas fa-mobile-alt"></i>
                          <div className="pq-footer-items-info">
                            <h4>Call Us On</h4>
                            <Link to="tel:+91 7039003001">
                              <span>+91 7039 003 001</span>
                              <br />
                            </Link>
                            <Link to="tel:+91 22-40134347">
                              <span>+91 22-40134347</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="pq-footer-items border-0">
                          <i className="far fa-envelope-open"></i>
                          <div className="pq-footer-items-info">
                            <h4>Email Us</h4>
                            <Link to="mailto:support@version-next.com">
                              <span>support@version-next.com</span>
                              <br />
                            </Link>
                            <Link to="mailto:sales@version-next.com">
                              <span>sales@version-next.com</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="container mbPadLR">
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="widget">
                      <Link
                        to="/"
                        className="fooImgAnch"
                        ref={scrollRef}
                        onClick={scrollToTop}
                      >
                        <img
                          src={whitelogo}
                          className="pq-footer-logo"
                          alt="VNT-footer-logo"
                        />
                      </Link>
                      <p className="fooPara">
                        To develop effective and streamline process for your
                        online business growth and meet our clients need.
                      </p>
                      <div className="pq-footer-social">
                        <ul>
                          <li>
                            <Link
                              to="https://www.facebook.com/vntech"
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.linkedin.com/company/version-next-technologies-pvt--ltd-?trk=biz-companies-cym"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://twitter.com/versionnext"
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width="24px"
                                clipRule="evenodd"
                                baseProfile="basic"
                              >
                                <polygon
                                  fill="#fff"
                                  points="41,6 9.929,42 6.215,42 37.287,6"
                                />
                                <polygon
                                  fill="#fff"
                                  fillRule="evenodd"
                                  points="31.143,41 7.82,7 16.777,7 40.1,41"
                                  clipRule="evenodd"
                                />
                                <path
                                  fill="#fff"
                                  d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"
                                />
                              </svg>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.instagram.com/versionnext/"
                              target="_blank"
                            >
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="dmcaLinkWrap">
                        <div className="dmcaRow">
                          <Link
                            to="https://www.dmca.com/compliance/version-next.com"
                            title="DMCA Compliance information for version-next.com"
                            target="_blank"
                          >
                            <span>DMCA</span>
                            <span>PROTECTED</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3  col-md-6">
                    <div className="widget mb-sm-0">
                      <h4 className="footer-title">Our Services</h4>
                      <div className="menu-useful-service-links-container">
                        <ul id="menu-useful-service-links" className="menu">
                          <li>
                            <Link to="/software-development">
                              Software Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-app-development">
                              Web Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/mobile-app-development">
                              Mobile Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/design-and-graphics">
                              UI/UX Designing{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/aws-development">AWS Development</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3  col-md-6">
                    <div className="widget mt-sm-0">
                      <h4 className="footer-title d-md-block d-none">&nbsp;</h4>
                      <div className="menu-useful-link-container">
                        <ul id="menu-usefull-link" className="menu">
                          <li>
                            <Link to="/firebase-development">
                              Firebase Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/nodejs-development">
                              NodeJS Development{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/vuejs-development">
                              VueJS Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/wordpress-development">
                              WordPress Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/react-native-development">
                              React-Native App Development
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3  col-md-6">
                    <div className="widget">
                      <h4 className="footer-title">Hire Developers</h4>
                      <div className="menu-useful-link-container">
                        <ul id="menu-usefull-link" className="menu">
                          <li>
                            <Link to="/hire-react-js-developers">
                              React.Js Developers
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-asp-dotnet-developers">
                              ASP.NET Developers
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-java-developers">
                              Java Developers
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-devops-developers">
                              DevOps Developers
                            </Link>
                          </li>
                          <li>
                            <Link to="/hire-pyhton-developers">
                              Python Developers
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pq-copyright-footer">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                    <span className="pq-copyright">
                      © 2023 Version Next Technologies. All Rights Reserved.
                    </span>
                  </div>
                  <div className="col-lg-6 col-md-6 d-flex justify-content-end align-items-center mobFoo">
                    <div className="pq-footer-link">
                      <ul className="mb-0">
                        <li>
                          <Link to="/career">Career</Link>
                        </li>
                        <li>
                          <Link to="/terms-conditions">Term Of Use</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
}

export default Footer;
