import React from "react";
import ContactForm from "../ContactForm/ContactForm";
import { Link } from "react-router-dom";

function ContactFormSection() {
  return (
    <div className="container">
      <div className="formTitle">
        <h3 className="lightText">
          Start <span className="textGrade">growing</span>
        </h3>
        <h3 className="boldText">your business with us</h3>
        <p>
          Our development team exclusively leverages modern and scalable
          technologies to precisely execute mobile and web applications
          according to your vision.
        </p>
      </div>
      <div className="mainFormPageSection">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="conLeftData">
              <h5>Call Us</h5>
              <div className="conLeftBox">
                <div className="col-1 px-0">
                  <div className="iconWraps">
                    <span className="material-symbols-outlined">
                      phone_iphone
                    </span>
                  </div>
                </div>
                <div className="col-10 px-0">
                  <div className="innerPart">
                    <p>
                      <Link to="tel:+91 7039 003 001">+91 7039 003 001</Link>
                    </p>
                    <p>
                      <Link to="tel:+91 22-40134347">+91 22-40134347</Link>
                    </p>
                  </div>
                </div>
              </div>
              <h5>Email Us</h5>
              <div className="conLeftBox">
                <div className="col-1 px-0">
                  <div className="iconWraps">
                    <span className="material-symbols-outlined">mail</span>
                  </div>
                </div>
                <div className="col-10- px-0">
                  <div className="innerPart">
                    <p>support@version-next.com</p>
                    <p>sales@version-next.com</p>
                  </div>
                </div>
              </div>
              <h5>Address</h5>
              <div className="conLeftBox">
                <div className="col-1 px-0">
                  <div className="iconWraps">
                    <span className="material-symbols-outlined">
                      location_on
                    </span>
                  </div>
                </div>
                <div className="col-10 px-0">
                  <div className="innerPart">
                    <p>
                      607/608, Pranik Chamber, Saki Vihar Road, Sakinaka Metro,
                      Andheri East, Mumbai 400072, India
                    </p>
                  </div>
                </div>
              </div>
              <div className="contactSocialIconWrap">
                <ul>
                  <Link to="https://www.facebook.com/vntech" target="_blank">
                    <li>
                      <i className="fab fa-facebook-f"></i>
                    </li>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/version-next-technologies-pvt--ltd-?trk=biz-companies-cym"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-linkedin-in"></i>
                    </li>
                  </Link>
                  <Link to="https://twitter.com/versionnext" target="_blank">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="24px"
                        clip-rule="evenodd"
                        baseProfile="basic"
                      >
                        <polygon
                          fill="#212529"
                          points="41,6 9.929,42 6.215,42 37.287,6"
                        />
                        <polygon
                          fill="#212529"
                          fill-rule="evenodd"
                          points="31.143,41 7.82,7 16.777,7 40.1,41"
                          clip-rule="evenodd"
                        />
                        <path
                          fill="#212529"
                          d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"
                        />
                      </svg>
                    </li>
                  </Link>
                  <Link
                    to="https://www.instagram.com/versionnext/"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-instagram"></i>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12">
            <section id="innerContactSection">
              <div className="container ">
                <div className="row ">
                  <div className="noPad ">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactFormSection;
